<mat-expansion-panel
  #turnoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    *ngIf="permitir"
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Inventário</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/inventario/inventario']"
      *ngIf="buscarPermissao('Inventario') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()"
      >Inventário</a
    >
  </div>
</mat-expansion-panel>
