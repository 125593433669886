<app-cabecalho
  [cor]="cor"
  (mostrarEnvio)="onMostrarChange($event)"></app-cabecalho>
<app-menu-lateral (mostrar)="onMostrarChange($event)">
  <main class="c-app_conteudo">
    <!-- comentando notificacao -->
    <!-- <app-notificacao
      [mostrar]="this.mostrar"
      [data]="dataSource"
      class="notificacao"
      (notificacao)="notificacaoLida($event)"></app-notificacao> -->
    <div class="padding">
      <h1 class="c-app_titulo">{{ tituloPagina.titulo() }}</h1>
      <div class="c-app_container">
        <div class="logo-container" *ngIf="isHomeRoute">
          <img src="/assets/logo.svg" class="logo" />
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</app-menu-lateral>
